"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudyCardTracker = void 0;
const error_message_1 = require("../../enums/error-message");
class StudyCardTracker {
    constructor(tracker) {
        this.tracker = tracker;
    }
    trackImpression(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.SNOWPLOW_TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceImpressionContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'view'
        });
    }
    trackClick(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.SNOWPLOW_TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceProductContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'click'
        });
    }
}
exports.StudyCardTracker = StudyCardTracker;
