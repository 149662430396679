import { InteractionEvent } from "@/infrastructure/outbound";
import { IDataLayer } from "@/types";
import { IDataLakeEventDispatcher } from "./data-lake-event-dispatcher.interface";
import { EventName } from "@/enum";

export class DataLakeEventDispatcher implements IDataLakeEventDispatcher {

	private dataLayer: IDataLayer;

	constructor() {
		this.dataLayer = window['dataLayer'] as IDataLayer;
	}

	public trackStructuredEvent(params: {
		category?: string
		action: string,
		label?: string,
		property?: string,
		value?: number
	}, eventName: string = EventName.LEGACY_EVENT): void {

		this.dataLayer.push(new InteractionEvent({
			eventName,
			...params
		}));
	}
}
