"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartnerIncentiveImpressionData = void 0;
const ecommerce_tracking_data_class_1 = require("./ecommerce-tracking-data.class");
class PartnerIncentiveImpressionData extends ecommerce_tracking_data_class_1.EcommerceTrackingData {
    getTrackingContext() {
        return {
            id: this.getProductId(),
            name: this.listPageType,
            list: this.listName,
            brand: this.getOrganisationId(),
            category: undefined,
            variant: undefined,
            position: this.listPosition,
            price: undefined,
            currency: undefined
        };
    }
}
exports.PartnerIncentiveImpressionData = PartnerIncentiveImpressionData;
