"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tracker = void 0;
const tracker_importer_1 = require("./tracker-importer");
const category_validator_1 = require("../validators/category-validator");
const event_validator_1 = require("../validators/event-validator");
const action_validator_1 = require("../validators/action-validator");
const label_validator_1 = require("../validators/label-validator");
const property_validator_1 = require("../validators/property-validator");
const value_validator_1 = require("../validators/value-validator");
const product_1 = require("../../enums/product");
const error_message_1 = require("../../enums/error-message");
const event_name_1 = require("../../enums/event-name");
const scholarship_card_tracker_1 = require("./scholarship-card-tracker");
const study_card_tracker_1 = require("./study-card-tracker");
const partner_incentive_tracker_1 = require("./partner-incentive-tracker");
class Tracker {
    constructor(product) {
        this.product = product;
        this.tracker = null;
        this.dataLayerTracker = null;
        this.trackerImporter = null;
        this.structuredEventValidator = this.initStructuredEventValidators();
        this.initTrackerSupport();
    }
    trackStructuredEvent(trackingData, eventName = event_name_1.EventName.LEGACY_EVENT, trackerEnabler = { enableSnowplow: true, enableGTM: true }) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.SNOWPLOW_TRACKER_UNAVAILABLE);
        }
        if (this.dataLayerTracker === null) {
            throw new Error(error_message_1.ErrorMessage.DATA_LAYER_TRACKER_UNAVAILABLE);
        }
        trackingData['category'] = this.product;
        this.structuredEventValidator.validate(trackingData);
        if (trackerEnabler.enableSnowplow) {
            this.tracker('trackStructEvent', trackingData);
        }
        if (trackerEnabler.enableGTM) {
            this.dataLayerTracker.trackStructuredEvent(trackingData, eventName);
        }
    }
    trackExperimentEvent(trackingData, enableSnowplow = true, enableGTM = true) {
        trackingData.category = product_1.Product.EXPERIMENT;
        // By default, experiments are both tracked in Snowplow and GTM
        if (this.tracker !== null && enableSnowplow) {
            this.tracker('trackStructEvent', trackingData);
        }
        if (this.dataLayerTracker !== null && enableGTM) {
            this.dataLayerTracker.trackStructuredEvent(trackingData, event_name_1.EventName.EXPERIMENT_EVENT);
        }
    }
    trackStudyCardImpression(trackingData) {
        new study_card_tracker_1.StudyCardTracker(this.tracker).trackImpression(trackingData);
    }
    trackStudyCardClick(trackingData) {
        new study_card_tracker_1.StudyCardTracker(this.tracker).trackClick(trackingData);
    }
    trackProductView(trackingData) {
        if (this.tracker === null) {
            throw new Error(error_message_1.ErrorMessage.SNOWPLOW_TRACKER_UNAVAILABLE);
        }
        this.tracker('addEnhancedEcommerceProductContext', trackingData.getTrackingContext());
        this.tracker('trackEnhancedEcommerceAction', {
            action: 'detail'
        });
    }
    trackScholarshipCardImpression(trackingData) {
        new scholarship_card_tracker_1.ScholarshipCardTracker(this.tracker).trackImpression(trackingData);
    }
    trackScholarshipCardClick(trackingData) {
        new scholarship_card_tracker_1.ScholarshipCardTracker(this.tracker).trackClick(trackingData);
    }
    trackPartnerIncentiveImpression(trackingData) {
        new partner_incentive_tracker_1.PartnerIncentiveTracker(this.tracker).trackImpression(trackingData);
    }
    initStructuredEventValidators() {
        const eventValidator = new event_validator_1.EventValidator();
        eventValidator.addValidator(new category_validator_1.CategoryValidator);
        eventValidator.addValidator(new action_validator_1.ActionValidator);
        eventValidator.addValidator(new label_validator_1.LabelValidator);
        eventValidator.addValidator(new property_validator_1.PropertyValidator);
        eventValidator.addValidator(new value_validator_1.ValueValidator);
        return eventValidator;
    }
    initTrackerSupport() {
        this.trackerImporter = new tracker_importer_1.TrackerImporter();
        this.trackerImporter.loadTracker(() => {
            this.tracker = this.trackerImporter.tracker;
        });
        this.trackerImporter.loadDataLayerTracker(() => {
            this.dataLayerTracker = this.trackerImporter.dataLayerTracker;
        });
        if (this.tracker && this.dataLayerTracker) {
            window['DatalakeEventTrackerLoaded'] = true;
            document.dispatchEvent(new Event('DatalakeEventTrackerLoaded'));
        }
    }
}
exports.Tracker = Tracker;
