import { IStudyInterests } from "@/types";

export class StudyInterests implements IStudyInterests {
	constructor(
		public readonly what?: number[],
		public readonly where?: number[],
		public readonly when?: string,
		public readonly attendance?: string[]
	) {}
}
