import { EventDispatcher, InternalEventDispatcher } from "@/infrastructure/outbound";

export class OrganisationEventListeners {

	private dispatcher: InternalEventDispatcher;

	constructor() {
		this.dispatcher = new EventDispatcher();

		this.setOrganisationPremiumClickListener();
	}

	private setOrganisationPremiumClickListener(): void {
		document.addEventListener('dataLayer_premium_organisation_click', () => {

			const statisticsId = window['studyportalsId'] as string;
			if (!statisticsId) {
				return;
			}

			this.dispatcher.dispatchPurchaseEvent({
				statisticsId
			});
		});
	}
}
